export let scaleChartOption = {
  legend: {
    show: true,
    right: 22,
    top: 0,
    itemWidth: 8,
    itemHeight: 8,
    icon: 'circle',
    textStyle: {
      fontSize: 14
    }
  },
  series: [
    {
      name: '比例分布',
      type: 'pie',
      radius: ['60%', '80%'],
      center: ['50%', '55%'],
      avoidLabelOverlap: false,
      startAngle: 230,
      label: {
        formatter: '{b}: {d}%'
      },
      color: ['#6E96D9', '#FFC84A'],
      data: [
        { value: 0, name: '男', label: { color: '#6E96D9' } },
        { value: 0, name: '女', label: { color: '#FFC84A' } }
      ]
    }
  ]
}
