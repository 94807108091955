<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>工作台</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container yt-container-flex">
      <div class="info-container">
        <div style="display: flex">
          <div class="yt-content has-border left">
            <p class="title">我的人才</p>
            <div class="statistic-wrapper">
              <div class="icon-background">
                <YTIcon :href="'#icon-jianli'" />
              </div>
              <ul class="has-border" style="flex-basis: 172px">
                <li style="margin-bottom: 5px">
                  <span style="font-size: 20px;font-weight: bold">{{ talentData.applyNum }}</span>
                  人
                </li>
                <li style="font-size: 12px">申请人数</li>
              </ul>
              <div style="width: 30px"></div>
              <ul class="has-border" style="flex-basis: 180px; flex-grow: 1">
                <li style="margin-bottom: 5px">
                  <span style="font-size: 20px;font-weight: bold">{{ talentData.examUserNum }}</span>
                  人
                </li>
                <li style="font-size: 12px">参加测试人数</li>
              </ul>
              <div style="width: 30px"></div>
              <ul style="flex-basis: 180px; flex-grow: 1">
                <li style="margin-bottom: 5px">
                  <span style="font-size: 20px;font-weight: bold">{{ talentData.passNum }}</span>
                  人
                </li>
                <li style="font-size: 12px">通过测试人数</li>
              </ul>
            </div>
          </div>
          <div class="yt-content has-border right">
            <p class="title">我的职位</p>
            <div class="statistic-wrapper">
              <div class="icon-background">
                <YTIcon :href="'#icon-qiye-fuben'" />
              </div>
              <ul class="has-border" style="flex-basis: 172px">
                <li style="margin-bottom: 5px">
                  <span style="font-size: 20px;font-weight: bold">{{ recruitmentData.recruitmentJobNum }}</span>
                  个
                </li>
                <li style="font-size: 12px">在线职位数</li>
              </ul>
              <div style="width: 30px"></div>
              <ul style="flex-basis: 180px; flex-grow: 1">
                <li style="margin-bottom: 5px">
                  <span style="font-size: 20px;font-weight: bold">{{ recruitmentData.offlineJobNum }}</span>
                  个
                </li>
                <li style="font-size: 12px">下线职位数</li>
              </ul>
              <div
                class="detail-button"
                @click="
                  $router.push({
                    name: 'jobRelease',
                    params: {
                      mode: '1',
                      fromRoute: 'jobList'
                    }
                  })
                "
              >
                发布职位
                <YTIcon style="font-size: 9px; margin-bottom: 1px" :href="'#icon-shuangjiantou'" />
              </div>
            </div>
          </div>
        </div>
        <div class="yt-content" style="margin: 10px 0">
          <el-dropdown trigger="click" placement="bottom-start" @command="selectJobId">
            <p style="cursor: pointer; color: #797979;">职位：{{ activeJob }}<i class="el-icon-arrow-down el-icon--right"></i></p>
            <el-dropdown-menu class="recruitment-dropdown" slot="dropdown">
              <el-dropdown-item v-for="item in jobOptions" :key="item.jobId" :command="item.jobId" :class="{ active: item.jobId === jobId }">
                {{ item.jobName }}
                <i class="el-icon-check el-icon--right"></i>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="display: flex; margin-bottom: 10px">
          <div class="yt-content has-border left chart-wrapper">
            <p class="title">
              职位人才分析
              <span class="filters">
                <span :class="{ active: analysisMode === '0' }" @click="switchMode('0')">周</span>
                <span :class="{ active: analysisMode === '1' }" @click="switchMode('1')">月</span>
              </span>
            </p>
            <div style="height: 220px" ref="analysisChart"></div>
          </div>
          <div class="yt-content has-border right chart-wrapper">
            <p class="title">候选人阶段变化</p>
            <div class="funnel-wrapper">
              <div class="funnel first">
                <span>{{ candidateData.applyNum }}</span>
              </div>
              <div class="funnel second">
                <span>{{ candidateData.examUserNum }}</span>
              </div>
              <div class="funnel third">
                <span>{{ candidateData.passNum }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; margin-bottom: 10px">
          <div class="yt-content has-border left chart-wrapper">
            <p class="title">比例分布</p>
            <div style="height: 220px" ref="scaleChart"></div>
          </div>
          <div class="yt-content has-border right chart-wrapper">
            <p class="title">年龄分布</p>
            <div style="height: 220px" ref="ageChart"></div>
          </div>
        </div>
        <div class="yt-content has-border">
          <p class="title" style="margin-bottom: 12px">
            最新申请人员
            <span class="detail-button" style="float: right" @click="$router.push('/manage/recruitment/staff')">
              查看更多
              <YTIcon style="font-size: 9px; margin-bottom: 1px" :href="'#icon-shuangjiantou'" />
            </span>
          </p>
          <el-table ref="staffTable" :data="applyList" v-loading="applyLoading" element-loading-text="加载中" class="yt-table">
            <el-table-column label="职位名称" prop="jobName" show-overflow-tooltip />
            <el-table-column label="姓名" prop="userName" show-overflow-tooltip />
            <el-table-column label="申请时间" show-overflow-tooltip>
              <template slot-scope="scope"> {{ $formatTime(scope.row.createTime, 'yyyy-MM-dd') }} </template>
            </el-table-column>
            <el-table-column label="笔试" prop="examRank" show-overflow-tooltip />
          </el-table>
        </div>
      </div>
      <div class="yt-content log-container has-border">
        <p class="title">日志列表</p>
        <Calendar class="calendar" :defaultDate="activeDate" @change="handleDateChange"></Calendar>
        <ul v-if="logList.length > 0 || logLoading">
          <li v-for="(item, index) in logList" :key="index" class="apply-card" @click="handleClickLog(item)">
            <div
              class="first-letter"
              :style="{ backgroundColor: colorEnum[item.initialLetter.charCodeAt(0) - 4 * Math.trunc(item.initialLetter.charCodeAt(0) / 4)] }"
            >
              {{ item.initialLetter }}
            </div>
            <div class="text-ellipsis">
              <p>{{ item.userName }}</p>
              <el-tooltip effect="dark" :content="item.message" placement="top">
                <span>{{ item.message }}</span>
              </el-tooltip>
            </div>
            <div class="create-time">{{ item.time }}</div>
          </li>
        </ul>
        <div v-else style="text-align: center; padding-bottom: 20px">
          <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import Calendar from '@components/common/version2/Calendar'
import { analysisChartOption } from '@components/manage/recruitment/charts/analysisChart'
import { scaleChartOption } from '@components/manage/recruitment/charts/scaleChart'
import { ageChartOption } from '@components/manage/recruitment/charts/ageChart'
import jobApi from '@api/job'
export default {
  name: 'Index',
  components: { Calendar, YTIcon },
  data() {
    return {
      talentData: {
        applyNum: 0,
        examUserNum: 0,
        passNum: 0
      },
      recruitmentData: {
        offlineJobNum: 0,
        recruitmentJobNum: 0
      },
      jobOptions: [], //职位筛选
      activeJob: '',
      jobId: '',
      analysisMode: '0', //职位人才分析周/月
      analysisChart: null,
      candidateData: {
        applyNum: 0,
        examUserNum: 0,
        passNum: 0
      },
      scaleChart: null,
      ageChart: null,
      applyList: [],
      applyLoading: false,
      activeDate: new Date(),
      logList: [], //申请列表
      logLoading: true,
      colorEnum: ['#58BE6B', '#2878FF', '#FFB200', '#FF5050']
    }
  },
  mounted() {
    this.getTalentNum()
    this.getRecruitmentNum()
    this.getAllJobName()
    this.getJobTalentInfoByDate()
    window.onresize = () => {
      if (this.analysisChart) {
        this.analysisChart.resize()
      }
      if (this.scaleChart) {
        this.scaleChart.resize()
      }
      if (this.ageChart) {
        this.ageChart.resize()
      }
    }
  },
  destroyed() {
    this.ageChart.dispose()
    this.ageChart = null
    this.analysisChart.dispose()
    this.analysisChart = null
    this.scaleChart.dispose()
    this.scaleChart = null
  },
  methods: {
    getTalentNum() {
      // 我的人才
      jobApi.getTalentNum().then(res => {
        this.talentData = res.res
      })
    },
    getRecruitmentNum() {
      // 我的职位
      jobApi.getRecruitmentNum().then(res => {
        this.recruitmentData = res.res
      })
    },
    getAllJobName() {
      // 职位筛选下拉
      jobApi.getAllJobName().then(res => {
        this.jobOptions = res.res
        this.selectJobId(res.res[0].jobId)
      })
    },
    getJobDateTalentInfo() {
      this.analysisChart = null
      jobApi.getJobDateTalentInfo(this.jobId, this.analysisMode).then(res => {
        if (this.analysisChart === null) {
          this.analysisChart = this.$echarts.init(this.$refs.analysisChart)
        }
        const option = this.$deepCopy(analysisChartOption)
        res.res.forEach(item => {
          option.xAxis.data.push(item.date)
          option.series[0].data.push(item.applyNum)
          option.series[1].data.push(item.examUserNum)
          option.series[2].data.push(item.passNum)
        })
        this.analysisChart.setOption(option)
      })
    },
    getJobTalentInfo() {
      this.scaleChart = null
      this.ageChart = null
      jobApi.getJobTalentInfo(this.jobId).then(res => {
        this.candidateData = { ...res.res.talentJobVO }
        const scaleOption = this.$deepCopy(scaleChartOption)
        if (this.scaleChart === null) {
          this.scaleChart = this.$echarts.init(this.$refs.scaleChart)
        }
        scaleOption.series[0].data[0].value = res.res.talentGenderRateVO.maleNum
        scaleOption.series[0].data[1].value = res.res.talentGenderRateVO.femaleNum
        this.scaleChart.setOption(scaleOption)
        const ageOption = this.$deepCopy(ageChartOption)
        res.res.talentAgeAmountVOS.forEach(item => {
          ageOption.xAxis.data.push(item.ageRange)
          ageOption.series[0].data.push(item.amount)
        })
        if (this.ageChart === null) {
          this.ageChart = this.$echarts.init(this.$refs.ageChart)
        }
        this.ageChart.setOption(ageOption)
      })
    },
    getResumeSender() {
      jobApi
        .searchLatestResumeSender(0, 20, { jobId: [this.jobId], name: '' })
        .then(res => {
          this.applyList = res.res.data
        })
        .finally(() => {
          this.logLoading = false
        })
    },
    selectJobId(id) {
      // 职位名称筛选
      this.jobId = id
      this.activeJob = this.jobOptions.find(item => item.jobId === id).jobName
      if (this.analysisChart) {
        this.analysisChart.dispose()
      }
      if (this.ageChart) {
        this.ageChart.dispose()
      }
      if (this.scaleChart) {
        this.scaleChart.dispose()
      }
      this.getJobDateTalentInfo()
      this.getJobTalentInfo()
      this.getResumeSender()
    },
    switchMode(mode) {
      this.analysisMode = mode
      this.analysisChart.dispose()
      this.getJobDateTalentInfo()
    },
    getJobTalentInfoByDate() {
      jobApi.getJobTalentInfoByDate(this.$formatTime(this.activeDate, 'yyyy-MM-dd')).then(res => {
        this.logList = res.res
      })
    },
    handleDateChange(date) {
      this.activeDate = date
      this.getJobTalentInfoByDate()
    },
    handleClickLog(data) {
      this.$router.push({
        name: 'staffApplyList'
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/recruitmentDropdownMenu';
.yt-main {
  padding-top: 0;
  .yt-container-flex {
    //justify-content: flex-start !important;
  }
}
.yt-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .yt-content {
    height: auto;
    position: relative;
    padding: 10px 20px;
    &.has-border {
      &:before {
        content: '';
        width: 4px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 10px;
        background-color: #448bff;
        border-radius: 1px;
      }
    }
    .title {
      line-height: 20px;
      color: #666;
    }
  }
}
.info-container {
  width: 700px;
  flex-grow: 1;
  margin-right: 10px;
  .yt-content {
    flex-grow: 1;
    &.left {
      width: calc(50% + 10px);
      margin-right: 10px;
    }
    &.right {
      width: calc(50% - 10px);
    }
    &.chart-wrapper {
      padding: 10px 0;
      .title {
        padding: 0 20px;
      }
    }
  }
  .statistic-wrapper {
    .flexStyle(flex, flex-start);
    margin-top: 20px;
    .icon-background {
      .flexStyle();
      flex-shrink: 0;
      width: 56px;
      height: 56px;
      margin-right: 20px;
      background-color: #e6f0ff;
      border-radius: 6px;
    }
    svg {
      font-size: 33px;
      color: #448bff;
    }
    .has-border {
      position: relative;
      &:after {
        content: '';
        width: 1px;
        height: 40px;
        background-color: #d3d3d3;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }
  }
  .detail-button {
    align-self: flex-end;
    flex-shrink: 0;
    margin-bottom: 1px;
    color: #448bff;
    cursor: pointer;
  }
  .filters {
    float: right;
    span {
      display: inline-block;
      width: 48px;
      height: 20px;
      line-height: 18px;
      text-align: center;
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
      cursor: pointer;
      &:first-child {
        border-radius: 4px 0 0 4px;
        border-left: 1px solid #e8e8e8;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
        border-right: 1px solid #e8e8e8;
      }
      &.active {
        line-height: 20px;
        background-color: #2878ff;
        border: none;
        color: #ffffff;
      }
    }
  }
}
.log-container {
  width: 300px;
  .calendar {
    margin: 20px 0 30px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }
  ul {
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 0 20px;
    max-height: 1414px;
    overflow-y: auto;
  }
  .apply-card {
    .flexStyle(flex, flex-start, flex-start);
    margin-bottom: 40px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .first-letter {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      line-height: 32px;
      margin-right: 12px;
      text-align: center;
      color: #ffffff;
      border-radius: 50%;
    }
    p {
      font-size: @medium;
      line-height: 20px;
    }
    span {
      color: #797979;
    }
    .create-time {
      flex-grow: 1;
      text-align: right;
      color: #a5a5a5;
    }
  }
}
.recruitment-dropdown {
  &.el-dropdown-menu {
    width: auto;
  }
}
.funnel-wrapper {
  .flexStyle(flex);
  flex-direction: column;
  height: 220px;
}
.funnel {
  position: relative;
  text-align: center;
  border-top: 56px solid;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  &:before {
    position: absolute;
    top: -32px;
    color: #6c6c6e;
  }
  &.first {
    width: 220px;
    margin-left: 60px;
    margin-bottom: 10px;
    border-top-color: #5293ff;
    &:before {
      content: '申请数';
      left: -100px;
    }
  }
  &.second {
    width: 180px;
    margin-left: 60px;
    margin-bottom: 10px;
    border-top-color: #7fadff;
    &:before {
      content: '测试数';
      left: -120px;
    }
  }
  &.third {
    width: 140px;
    margin-left: 60px;
    border-top-color: #bfd5ff;
    &:before {
      content: '通过数';
      left: -140px;
    }
  }
  span {
    color: #ffffff;
    font-size: 18px;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
</style>
