export let analysisChartOption = {
  grid: {
    top: 40,
    left: 40,
    right: 22,
    bottom: 23
  },
  legend: {
    show: true,
    right: 22,
    top: 10,
    itemWidth: 8,
    itemHeight: 8,
    icon: 'circle',
    textStyle: {
      fontSize: 14
    }
  },
  xAxis: {
    type: 'category',
    data: [],
    axisTick: {
      show: false
    }
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      name: '申请人数',
      data: [],
      type: 'bar',
      stack: 'total',
      barWidth: 12,
      color: '#7CAEEF',
      itemStyle: {
        borderRadius: [0, 0, 2, 2]
      }
    },
    {
      name: '测试人数',
      data: [],
      type: 'bar',
      stack: 'total',
      barWidth: 12,
      color: '#46B2A7'
    },
    {
      name: '邀请人数',
      data: [],
      type: 'bar',
      stack: 'total',
      barWidth: 12,
      color: '#FFC251',
      itemStyle: {
        borderRadius: [2, 2, 0, 0]
      }
    }
  ]
}
