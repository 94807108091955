export let ageChartOption = {
  grid: {
    top: 40,
    left: 40,
    right: 22,
    bottom: 23
  },
  legend: {
    show: true,
    right: 22,
    top: 10,
    itemWidth: 8,
    itemHeight: 8,
    icon: 'circle',
    textStyle: {
      fontSize: 14
    }
  },
  xAxis: {
    type: 'category',
    data: [],
    axisTick: {
      show: false
    }
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [],
      type: 'bar',
      barWidth: 16,
      color: '#289DE1'
    }
  ]
}
